<template>
  <v-row>
    <!-- toolbar -->
    <v-col cols="12">
      <v-toolbar dense flat rounded color="primary" dark>
        <v-toolbar-title class="text-capitalize">{{
          $route.path.replace("/", "").replaceAll("-", " ")
        }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-col>

    <!-- items/auctioneers -->
    <v-col cols="12">
      <v-card :loading="loading" outlined>
        <v-data-iterator
          :items="auctioneers"
          item-key="id"
          :search="search"
          no-data-text="Please add an auctioneer to continue"
          :loading="loading"
          :items-per-page="6"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'show-current-page': true,
          }"
        >
          <!-- top -->
          <template v-slot:header>
            <div class="pa-4 d-flex">
              <v-btn
                fab
                color="primary"
                depressed
                small
                @click="
                  searching = !searching;
                  !searching && search ? (search = '') : '';
                "
                :loading="loading"
                :disabled="loading"
              >
                <v-icon>
                  {{ searching ? "mdi-close" : "mdi-magnify" }}
                </v-icon></v-btn
              >
              <div style="width:500px">
                <v-slide-x-transition>
                  <v-text-field
                    v-show="searching"
                    label="Search"
                    v-model="search"
                    outlined
                    dense
                    hide-details
                    clearable
                    class="ml-5"
                    filled
                    autofocus
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-slide-x-transition>
              </div>
              <v-spacer />
              <v-btn
                fab
                color="primary"
                depressed
                small
                style="margin-top:-3px"
                :loading="loading"
                :disabled="loading"
                @click="
                  mode = 'new';
                  dialog = true;
                "
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </template>

          <!-- items -->
          <template v-slot:default="{ items }">
            <draggable
              v-model="auctioneers"
              class="d-flex flex-row flex-wrap"
              ghost-class="primary"
              @change="setNewItemOrders"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(i, index) in items"
                :key="i.id"
              >
                <v-card
                  outlined
                  :loading="loading"
                  class="d-flex flex-column"
                  style="height: 100%"
                >
                  <div class="text--primary flex-grow-1">
                    <!-- name -->
                    <div
                      v-ripple
                      class="d-flex py-3 px-4 cursorPointer justify-space-between"
                      @click="copyText(i.name)"
                    >
                      <div class="text-subtitle-1 font-weight-bold">
                        {{ i.name }}
                      </div>
                      <v-icon xSmall right>mdi-content-copy</v-icon>
                    </div>

                    <!-- mailingAddress -->
                    <div
                      v-ripple
                      class="d-flex py-3 px-4 cursorPointer justify-space-between"
                      @click="copyText(i.address)"
                    >
                      <div class="text-subtitle-2 font-weight-bold">
                        {{ i.address }}
                      </div>
                      <v-icon xSmall right>mdi-content-copy</v-icon>
                    </div>
                    <v-divider />

                    <!-- info -->
                    <div
                      v-ripple
                      class="d-flex pb-2 px-4 cursorPointer justify-space-between grey lighten-3 pt-6"
                      @click="copyText(i.info)"
                    >
                      <div
                        class="text-subtitle-2 font-weight-medium"
                        v-html="i.info"
                      />

                      <v-icon xSmall right>mdi-content-copy</v-icon>
                    </div>
                    <v-divider />

                    <!-- auctioneers -->
                    <div class="pa-4">
                      <p class="text-caption font-weight-bold">
                        {{
                          i.auctioneers.length
                            ? "Auctioneers"
                            : "No Auctioneers Assigned"
                        }}
                      </p>
                      <div
                        class="d-flex"
                        style="gap:10px"
                        v-if="i.auctioneers.length"
                      >
                        <v-chip
                          small
                          filter
                          v-for="item in itemAuctioneers(i)"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </v-chip>
                      </div>
                    </div>

                    <v-divider />
                    <!-- salesYtd -->

                    <div
                      v-ripple
                      class="d-flex py-3 px-4 cursorPointer justify-space-between mt-2"
                      @click="copyText(formatCurrency(i.salesYtd))"
                    >
                      <div
                        class="text-subtitle-2 font-weight-bold primary--text text--darken-1"
                      >
                        Total Sales YTD: {{ formatCurrency(i.salesYtd) }}
                      </div>
                      <v-icon xSmall right>mdi-content-copy</v-icon>
                    </div>
                  </div>

                  <v-divider />
                  <v-card-actions>
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      icon
                      color="error"
                      @click="
                        mode = 'delete';
                        item = i;
                        item.index = index;
                        dialog = true;
                      "
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      icon
                      @click="
                        mode = 'edit';
                        item = i;
                        dialog = true;
                      "
                      :loading="loading"
                      :disabled="loading"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </draggable>
          </template>
        </v-data-iterator>
      </v-card>
    </v-col>

    <!-- dialog -->
    <v-dialog v-model="dialog" width="650" eager>
      <v-card :loading="loading">
        <v-card-title class="text-h5 d-flex">
          <div v-if="mode == 'new'">
            Add New Auctioneer
          </div>
          <div v-if="mode == 'edit'">Edit Auctioneer {{ item.name }}</div>

          <div v-if="mode == 'delete'">
            <span class="mr-2 red--text"
              ><v-icon color="error" class="mr-2 mb-1">mdi-alert-outline</v-icon
              >Delete Auctioneer</span
            >
            <span class="primary--text font-weight-bold">{{ item.name }}?</span>
          </div>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <!-- form -->
        <v-card-text class="text--primary body-1">
          <div v-if="mode == 'delete'">
            <p class="mt-6 font-weight-medium">
              Delete auctioneer {{ item.name }}?
            </p>
            <p class="mt-6 font-weight-medium">
              This will also remove {{ item.name }} from all other auctioneers
              they've been assigned to.
            </p>
            <p class="mt-6 font-weight-medium red--text">
              This action cannot be undone.
            </p>
          </div>

          <v-form
            ref="form"
            :disabled="loading"
            v-show="mode == 'new' || mode == 'edit'"
          >
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model.trim="item.name"
                  :rules="[requiredField]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  id="placesInput"
                  label="Mailing Address"
                  v-model.trim="item.address"
                  append-icon="mdi-map-marker-outline"
                  :rules="[requiredField]"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <h5
                  class="font-weight-regular mb-2"
                  :class="{ 'red--text': !tipTapValid }"
                >
                  Important Info *
                </h5>
                <v-input
                  :rules="[tipTapValid]"
                  v-model="item.info"
                  :error="tipTapValid"
                  :error-messages="tipTapValidMessage"
                >
                  <tiptap-vuetify
                    style="width:100%"
                    @blur="checkTipTapValid(item)"
                    @input="checkTipTapValid(item)"
                    v-model.trim="item.info"
                    :extensions="extensions"
                    :card-props="{
                      outlined: true,
                      class: !tipTapValid ? 'body-2 redBorder' : 'body-2',
                    }"
                  />
                </v-input>
              </v-col>
              <v-col cols="12">
                <v-sheet
                  outlined
                  rounded
                  class="px-4 py-2"
                  v-if="auctioneers.some((i) => i.id != item.id)"
                >
                  <h5 class="font-weight-regular mb-2">
                    Sub Auctioneers
                  </h5>
                  <v-input v-model="item.auctioneers">
                    <v-chip-group v-model="item.auctioneers" column multiple>
                      <v-chip
                        filter
                        :value="item.id"
                        outlined
                        v-for="item in auctioneers.filter(
                          (i) => i.id != item.id
                        )"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-input>
                </v-sheet>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Total Sales YTD"
                  v-model.trim="item.salesYtd"
                  type="number"
                  hide-spin-buttons
                  :rules="[(v) => !!v || 'Field is required']"
                  :hint="formatCurrency(item.salesYtd)"
                  persistent-hint
                  @keyup.enter="submit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="dialog = false"
            :loading="loading"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="mode == 'new' || mode == 'edit'"
            color="primary"
            depressed
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{
              mode == "new"
                ? "  Add New Auctioneer"
                : `Save Changes to ${item.name}`
            }}
          </v-btn>
          <v-btn
            v-if="mode == 'delete'"
            color="error"
            depressed
            @click="
              mode = 'delete';
              deleteItem(item);
            "
            :loading="loading"
            :disabled="loading"
          >
            Delete Auctioneer {{ item.name }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// https://sortablejs.github.io/Vue.Draggable/#/simple
// https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/simple.vue
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import draggable from "vuedraggable";
import copyText from "@/mixins/copyText";
import portalsApp from "@/plugins/firebase";
import formatCurrency from "@/mixins/formatCurrency";
export default {
  mixins: [copyText, formatCurrency],
  components: {
    draggable,
    TiptapVuetify,
  },
  async mounted() {
    this.loading = true;
    await this.getItems();
    this.loading = false;
  },
  data: () => ({
    search: "",
    searching: "",
    auctioneers: [],
    loading: false,
    mode: null,
    dialog: false,
    item: {},
    tipTapValid: true,
    tipTapValidMessage: null,
    extensions: [
      History,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  methods: {
    getItems() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.auctioneers = [];
        portalsApp
          .firestore()
          .collection("auctioneers")
          // .orderBy("order")
          .orderBy("salesYtd", "desc")
          .get()
          .then((snap) => {
            if (snap.docs.length) {
              snap.docs.forEach((doc) => {
                this.auctioneers.push(doc.data());
                if (snap.docs.length == this.auctioneers.length) {
                  this.loading = false;
                  resolve();
                }
              });
            } else {
              this.loading = false;
              resolve();
            }
          })
          .catch((error) => {
            console.log("Error: getItems() " + error);
            reject();
          });
      });
    },
    async postTaskCleanUp() {
      return new Promise((resolve, reject) => {
        this.dialog = false;
        this.item = {};
        this.mode = null;
        this.tipTapValid = true;
        this.tipTapValidMessage = null;
        if (this.$refs.form) this.$refs.form.reset();
        this.loading = false;
        resolve();
      });
    },
    async deleteItem(item) {
      if (item.id == undefined) return;
      this.loading = true;

      // find all auctioneers that the auctioneer being deleted is assigned to
      let array = [];
      this.auctioneers.forEach((i) => {
        if (i.auctioneers.length && i.auctioneers.some((a) => a == item.id))
          array.push(i);
      });

      // delete that sub auctioneer from all auctioneers
      if (array.length) {
        for (let index = 0; index < array.length; index++) {
          const includesAuctioneerToRemove = array[index];
          includesAuctioneerToRemove.auctioneers = includesAuctioneerToRemove.auctioneers.filter(
            (c) => c != item.id
          );
          await portalsApp
            .firestore()
            .collection("auctioneers")
            .doc(includesAuctioneerToRemove.id)
            .update({
              auctioneers: includesAuctioneerToRemove.auctioneers,
            });
        }
      }

      this.auctioneers = this.auctioneers.filter((i) => i.id !== item.id);

      // delete item
      await portalsApp
        .firestore()
        .collection("auctioneers")
        .doc(item.id)
        .delete();

      // filter out local data here instead of calling db
      await this.getItems();

      // set new item.order
      const setNewItemOrders = async () => {
        return new Promise(async (resolve, reject) => {
          if (this.auctioneers.length) {
            let count = 0;
            for (let index = 0; index < this.auctioneers.length; index++) {
              const element = this.auctioneers[index];
              await portalsApp
                .firestore()
                .collection("auctioneers")
                .doc(element.id)
                .update({
                  order: index,
                });
              count = count + 1;
              if (count == this.auctioneers.length) {
                resolve();
              }
            }
          } else {
            resolve();
          }
        });
      };

      await setNewItemOrders();

      await this.getItems();

      await this.postTaskCleanUp();

      this.$toast.success("Auctioneer Deleted", {
        timeout: 2000,
      });
    },
    requiredField(e) {
      if (e == undefined) return "Field Required";
      if (!e.replace(/\s/g, "")) return "Field Required";
      return true;
    },
    checkTipTapValid(item) {
      if (item == undefined) {
        this.tipTapValid = false;
        this.tipTapValidMessage = "Field Required";
        return;
      }

      if (item.info == null || item.info.replace(/\s/g, "") === "<p></p>") {
        this.tipTapValid = false;
        this.tipTapValidMessage = "Field Required";
        return;
      }
      this.tipTapValid = true;
      this.tipTapValidMessage = null;
    },
    itemAuctioneers(item) {
      let array = [];
      this.auctioneers.forEach((i) => {
        if (item.auctioneers.find((a) => a == i.id)) array.push(i);
      });
      return array;
    },
    async submit() {
      this.checkTipTapValid(this.item);

      if (!this.$refs.form.validate() || !this.tipTapValid)
        return this.$toast.error("Please check form for errors", {
          timeout: 3000,
        });

      this.loading = true;
      this.item.salesYtd = parseFloat(this.item.salesYtd);

      if (this.mode == "new") {
        this.item.order = this.auctioneers.length;

        let newDoc = await portalsApp
          .firestore()
          .collection("auctioneers")
          .add(this.item);

        await portalsApp
          .firestore()
          .collection("auctioneers")
          .doc(newDoc.id)
          .update({
            id: newDoc.id,
          });

        await this.getItems();

        await this.postTaskCleanUp();

        this.$toast.success("Auctioneer Added", {
          timeout: 2000,
        });
      }

      if (this.mode == "edit") {
        await portalsApp
          .firestore()
          .collection("auctioneers")
          .doc(this.item.id)
          .update(this.item);
        this.$toast.success("Auctioneer Changes Saved", {
          timeout: 2000,
        });
        await this.postTaskCleanUp();
      }
    },
    async setNewItemOrders() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        let count = 0;
        if (this.auctioneers.length) {
          this.auctioneers.forEach((item, index) => {
            portalsApp
              .firestore()
              .collection("auctioneers")
              .doc(item.id)
              .update({
                order: index,
              })
              .then(async () => {
                count = count + 1;
                if (count == this.auctioneers.length) {
                  await this.getItems();
                  resolve();
                }
              });
          });
        } else {
          this.loading = false;
          resolve();
        }
      });
    },
  },
  watch: {
    mode(val) {
      if (!val || val == undefined || val == null) return;

      if (val == "new") {
        this.item.auctioneers = [];
        if (val == "new") this.item.info = null;
      }
      if (val == "new" || val == "edit") {
        this.$nextTick(() => {
          let placesInput = document.getElementById("placesInput");
          if (!placesInput) return;
          let autocomplete = new google.maps.places.Autocomplete(placesInput);
          google.maps.event.addListener(autocomplete, "place_changed", () => {
            let place = autocomplete.getPlace();
            let formattedAddress = place.formatted_address;
            this.item.address = formattedAddress;
          });
        });
      }
    },
    async dialog(val) {
      if (val == undefined || val == null) return;
      if (val == false) await this.postTaskCleanUp();
    },
  },
};
</script>

<style>
.pac-target-input::placeholder {
  color: transparent !important;
}

.redBorder {
  border-color: red !important;
}
</style>
